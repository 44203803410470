import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const IntegrationServices = () => {

    return (
        <section className="project-gallery">
            <div className="project-card">
                <StaticImage src="../../images/projects/port.jpg" className="index-image" alt="Port Area" />
                <h3>Port Area</h3>
                <h4>Data, voice and video network</h4>
            </div>
            <div className="project-card">
                <StaticImage src="../../images/projects/systems_integration.jpg" className="index-image" alt="Systems Integration" />
                <h3>Systems Integration</h3>
                <h4>Network Infrastructure and Gate Automation</h4>
            </div>
            <div className="project-card">
                <StaticImage src="../../images/projects/data_center.png" className="index-image" alt="Data Center and NOC Rehabilitation" />
                <h3>Data Center and NOC</h3>
                <h4>Construction and rehabilitation of cabling system and configuration of data center equipment</h4>
            </div>
        </section>
    )

}

export default IntegrationServices
