import React from "react"
import { StaticImage } from "gatsby-plugin-image"


const TelecommunicationProjects = () => {
    return (
        <section className="project-gallery">
            <div className="project-card">
                <StaticImage src="../../images/projects/tower_erection.jpg" className="index-image" alt="Tower Erection" />
                <h3>Tower Erection</h3>
                <h4>Civil, Electrical, Mechanical (CME) Design and Build</h4>
            </div>
            <div className="project-card">
                <StaticImage src="../../images/projects/ibs.jpg" className="index-image" alt="IBS Installation and Design" />
                <h3>IBS Installation and Design</h3>
                <h4>In-building System Deployment</h4>
            </div>
            <div className="project-card">
                <StaticImage src="../../images/projects/5g.jpg" className="index-image" alt="2G/3G/4G/LTE/5G" />
                <h3>2G/3G/4G/LTE/5G</h3>
                <h4>Installation and Network Upgrade</h4>
            </div>
        </section>
    )
}

export default TelecommunicationProjects
