import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import TelecommunicationsProjects from "../components/projects/TelecommunicationProjects"
import IntegrationProjects from "../components/projects/IntegrationProjects"

import { FaArrowCircleRight } from "react-icons/fa"

const ServicesPage = () => (
    <Layout>
        <Seo
            title="Projects"
            description="Martindale Technologies Inc. business cases and projects"
        />

        <section className="services-section">
            <section className="section-center">
                <h2 className="section-title">Our Projects</h2>

                <div className="projects-header">
                    <h3>
                        <span>Telecommunications</span>
                    </h3>
                </div>
                <div className="services-wrapper">
                    <TelecommunicationsProjects></TelecommunicationsProjects>
                </div>

                <div className="projects-header">
                    <h3>
                        <span>Systems Integration</span>
                    </h3>
                </div>
                <div className="services-wrapper">
                    <IntegrationProjects></IntegrationProjects>
                </div>
            </section>

            <div className="index-second contact-div">
                <Link to="/contact/">
                    <button className="btn">
                        Get in Touch
                        <FaArrowCircleRight className="btn-icon" />
                    </button>
                </Link>
            </div>
        </section>
    </Layout>
)

export default ServicesPage
